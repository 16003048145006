import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledProjectsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .projects-grid {
    ${({ theme }) => theme.mixins.resetList};
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .more-button {
    ${({ theme }) => theme.mixins.button};
    margin: 80px auto 0;
  }
  .project-title {
    font-size: 24px; /* Adjust as needed */
    color: var(--green); /* Adjust as needed */
    font-weight: bold; /* Optional: make the title bold */
    margin: 0 0 10px;

    a {
      color: inherit; /* Inherit the color from parent */
      text-decoration: none;

      &:hover {
        text-decoration: underline; /* Add underline on hover */
      }
    }
  }
`;

const StyledProject = styled.li`
  position: relative;
  padding: 1rem 0;
  border-bottom: 1px solid var(--lightest-navy);
  list-style-type: disc;
  margin-left: 20px;
`;

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      projects: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/content/news/" }
          frontmatter: { showInProjects: { ne: false } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              tech
              github
              external
            }
            html
          }
        }
      }
    }
  `);

  const [showMore, setShowMore] = useState(false);
  const revealTitle = useRef(null);
  const revealArchiveLink = useRef(null);
  const revealProjects = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 6;
  const projects = data.projects.edges.filter(({ node }) => node);
  const firstSix = projects.slice(0, GRID_LIMIT);
  const projectsToShow = showMore ? projects : firstSix;

  const projectInner = node => {
    const { frontmatter, html } = node;
    const { external, title, tech } = frontmatter;

    return (
      <>
        <div className="project-title">
          <a href={external} target="_blank" rel="noreferrer">
            {title}
          </a>
        </div>
        <div className="project-description" dangerouslySetInnerHTML={{ __html: html }} />
        {tech && (
          <ul className="project-tech-list">
            {tech.map((tech, i) => (
              <li key={i}>{tech}</li>
            ))}
          </ul>
        )}
      </>
    );
  };

  return (
    <section id="news">
      <StyledProjectsSection>
        <h2 ref={revealTitle}>News</h2>

        <ul className="projects-grid">
          {prefersReducedMotion ? (
            <>
              {projectsToShow &&
                projectsToShow.map(({ node }, i) => (
                  <StyledProject key={i}>{projectInner(node)}</StyledProject>
                ))}
            </>
          ) : (
            <TransitionGroup component={null}>
              {projectsToShow &&
                projectsToShow.map(({ node }, i) => (
                  <CSSTransition
                    key={i}
                    classNames="fadeup"
                    timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                    exit={false}>
                    <StyledProject
                      key={i}
                      ref={el => (revealProjects.current[i] = el)}
                      style={{
                        transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                      }}>
                      {projectInner(node)}
                    </StyledProject>
                  </CSSTransition>
                ))}
            </TransitionGroup>
          )}
        </ul>

        <button className="more-button" onClick={() => setShowMore(!showMore)}>
          Show {showMore ? 'Less' : 'More'}
        </button>
      </StyledProjectsSection>
    </section>
  );
};

export default Projects;
