import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  // min-height: 170vh;
  height: auto;
  padding: 15px;
  margin-top: 100px;


  @media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
    height: 200px;
    padding-top: var(--nav-height);
  }

  .item-1{
    height:900px;
    color:white;
    font
    backgroung-color: white;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 700px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

  //.coding-activity-chart {
  //  width: 100%; /* Adjust based on your layout needs */
  //  margin-top: 20px; /* Provides some space above the chart */
  //}
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi, my name is</h1>;
  const two = <h2 className="big-heading">Rohit Singh Rathaur,</h2>;
  const three = <h3 className="big-heading">an Independent AI Researcher</h3>;
  const four = (
    <>
      <p>
        I'm deeply engaged in learning AI Alignment, with a keen interest in{' '}
        <a
          href="https://www.alignmentforum.org/posts/jLAvJt8wuSFySN975/mechanistic-interpretability-quickstart-guide"
          target="_blank"
          rel="noreferrer">
          <i>Mechanistic Interpretability</i>
        </a>
        , <i>concept-based & Developmental Interpretability, and Aligning Language Models.</i> My
        current endeavors include spearheading the Thoth project as a Project Team Lead (PTL) within{' '}
        <a href="https://wiki.anuket.io/" target="_blank" rel="noreferrer">
          Anuket
        </a>{' '}
        at the
        <a href="https://lfnetworking.org/" target="_blank" rel="noreferrer">
          Linux Foundation Networking(LFN)
        </a>{' '}
        , where I'm committed to advancing AI technologies and applications in the
        telecommunications sector and beyond. Currently, I am collaborating with{' '}
        <a href="https://web.stanford.edu/~rsarkar/" target="_blank" rel="noreferrer">
          Rahul Sarkar
        </a>{' '}
        from the Institute for Computational and Mathematical Engineering at Stanford University on
        a review paper titled{' '}
        <a
          href="https://github.com/RohitRathore1/PDFs/blob/main/Under%20Process/INN.pdf"
          target="_blank"
          rel="noreferrer">
          A Review of Invertible Neural Networks: Theory and Applications.
        </a>{' '}
        This work aims to shed light on the theoretical underpinnings and practical applications of
        invertible neural networks, with the final draft of the paper under process.
      </p>
      <p>
        Here is my up-to-date coding activities information:
        <a href="https://wakatime.com/@f3f04b5d-ac8d-4f60-81ef-453ab7ebc37e">
          <img
            src="https://wakatime.com/badge/user/f3f04b5d-ac8d-4f60-81ef-453ab7ebc37e.svg"
            alt="Total time coded since Aug 9 2020"
          />
        </a>
        {/*/!* Embed the Wakatime chart *!/*/}
        {/*<img*/}
        {/*  className="coding-activity-chart"*/}
        {/*  src="https://wakatime.com/share/@TeAmp0is0N/2f413b84-8295-42d9-b559-72382e4cf0ce.svg"*/}
        {/*  alt="Coding Activity Chart"*/}
        {/*/>*/}
        <figure>
          <embed src="https://wakatime.com/share/@TeAmp0is0N/2f413b84-8295-42d9-b559-72382e4cf0ce.svg"></embed>
        </figure>
      </p>
    </>
  );
  const five = (
    <a
      className="email-link"
      href="https://wiki.anuket.io/display/HOME/Thoth"
      target="_blank"
      rel="noreferrer">
      Check out my work at The Linux Foundation Networking!
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }} className="item-${i}">
                  {item}
                </div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
